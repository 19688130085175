import React, { useState, useEffect } from 'react';
import { Stepper, Step} from '@mui/material';
import '../index.css'
import '../assets/main'
import { handleOptionClick } from './logic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { logSubmit } from './logic';

const theme = createTheme({
    components: {
      MuiStepper: {
        styleOverrides: {
          root: {
            display: 'block', 
            WebkitBox: 'initial', 
            WebkitFlex: 'initial',
          },
        },
      },
    },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
    },
});

/* inicio steps */
const steps = [ 
    /* step 1 */ 
    { content: <div className="wizard-forms">
    <div className="inner clearfix p-8 z-10 sticky sm:p-2">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '0%"> '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Design Organizacional</h2>
                    <p className="font-medium text-black-opac text-base sm:text-sm 2xl:text-lg">Os processos da empresa garantem a fluidez necessária para a execução de projetos de inovação (sejam em áreas como compras, jurídico, projetos, etc)?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p1_ul">
                        <li className='sm:text-sm 2xl:text-lg md:text-base' id="p1_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm 2xl:text-lg md:text-base' id="p1_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm 2xl:text-lg md:text-base' id="p1_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm 2xl:text-lg md:text-base' id="p1_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm 2xl:text-lg md:text-base' id="p1_op_6">Nada de acordo </li>
                        <li className='sm:text-sm 2xl:text-lg md:text-base' id="p1_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div> 
            {/* <label id="p1_label" className="required" style={{color: 'red '}}>Esta informação é obrigatória. Por favor selecione uma opção</label> */}
        </div>
    </div>
</div> }, /* step 2 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '3.3% '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Design Organizacional</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Os objetivos de inovação e seus projetos estão estritamente alinhados e claros entre as pessoas?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p2_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p2_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p2_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p2_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p2_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p2_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p2_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> }, /* step 4 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute"> 
            {/* <span>2 de 38 Completo</span>
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '6.6% '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Design Organizacional</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A liderança sabe cobrar resultados de inovação com clareza e se porta como facilitadora do processo?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p3_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p3_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p3_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p3_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p3_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p3_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p3_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 5*/ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '9.9% '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Design Organizacional</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A empresa quebra silos entre as áreas e gera proximidade com a área de inovação garantindo a independência, agilidade e autonomia dos times que desenvolvem projetos de inovação?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p4_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p4_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p4_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p4_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p4_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p4_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p4_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 6 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '13.3 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Design Organizacional</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Quando um colaborador possui uma ideia para um projeto inovador, ele sabe exatamente para onde levar essa ideia e quais os critérios para sua implementação ou não?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p5_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p5_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p5_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p5_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p5_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p5_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p5_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 7 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '16.6 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Pense como investidor</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">As teses de inovação estão alinhadas com a estratégia da empresa, são de conhecimento de toda empresa e ditam o que será feito como inovação?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p6_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p6_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p6_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p6_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p6_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p6_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p6_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 8 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '19.9 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Pense como investidor</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A empresa não investe apenas em inovações próximas ao seu core business, mas abarca também inovações adjacentes e disruptivas?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p7_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p7_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p7_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p7_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p7_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p7_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p7_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 9 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '23.3 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Pense como investidor</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A empresa possui indicadores de inovação baseados no estágio de maturidade de cada projeto e toma decisões sobre esses projetos baseada nessas metricas?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p8_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p8_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p8_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p8_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p8_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p8_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p8_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 10 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '26.6 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Pense como investidor</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">O orçamento para os projetos de inovação é reservado e consegue ser facilmente acionado?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p9_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p9_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p9_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p9_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p9_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p9_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p9_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 11 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '29.9 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Pense como investidor</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Existe um comitê formalizado que acompanha os projetos de inovação?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p10_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p10_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p10_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p10_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p10_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p10_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p10_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 12 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '33.3 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Gestão ágil</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A empresa utiliza o ágil em diversos projetos e áreas, de forma que as metodologias escolhidas funcionem para a estratégia e execução de projetos de inovação?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p11_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p11_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p11_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p11_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p11_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p11_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p11_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 13 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '36.6 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Gestão ágil</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A empresa e times possuem alinhamentos cruzados entre si, comunicação constante e simples que garante a autonomia dos projetos de inovação?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p12_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p12_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p12_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p12_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p12_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p12_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p12_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 14 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '39.9 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Gestão ágil</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Os direcionamentos da empresa que são feitos para os times são influenciados pela política corporativa?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p13_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p13_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p13_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p13_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p13_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p13_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p13_op_4">Não se aplica</li>
                    </ul>
                </div> 
                {/* <div className="comment-box">
                    <p>Justificativa</p>
                    <textarea name="p13_justification"></textarea>
                </div> */}
            </div>
        </div>
    </div>
</div> },
 /* div 15 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '43.2 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Gestão ágil</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">As metas de inovação são claras e desdobradas nos níveis dos times com uma cadência periódica de acompanhamento e capacitação?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p14_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p14_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p14_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p14_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p14_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p14_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p14_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 16 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '46.6 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Gestão ágil</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Os perfis dos profissionais são levados em consideração para formar um time de projeto?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p15_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p15_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p15_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p15_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p15_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p15_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p15_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 17 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '49.9 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Mate seu negócio</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Existe um planejamento claro e documentado de como capturar valor futuro do mercado em que atua, em adjacentes e/ou em novos mercados?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p16_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p16_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p16_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p16_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p16_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p16_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p16_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 18 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '53.2 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Mate seu negócio</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Existe um orçamento alocado para investir em iniciativas futuras?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p17_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p17_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p17_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p17_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p17_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p17_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p17_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 19 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '56.6 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Mate seu negócio</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">O planejamento, orçamento e processos garantem independência de execução?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p18_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p18_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p18_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p18_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p18_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p18_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p18_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 20 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '59.9 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Mate seu negócio</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A empresa parece disposta a fazer mudanças bruscas de posicionamento, mercado e ou produto, caso seja necessário manter-se ativo no mercado?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p19_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p19_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p19_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p19_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p19_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p19_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p19_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 21 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '63.2 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Mate seu negócio</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A empresa conhece as tendências que impactam seu modelo de negócios, seus produtos e o comportamento do seu consumidor?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p20_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p20_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p20_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p20_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p20_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p20_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p20_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 22 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '66.6 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Trabalhe com Parceiros</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A empresa definiu uma política para atuação em corporate venture (através de ações de inovação interna ou através de startups e/ou parceiros)?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p21_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p21_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p21_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p21_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p21_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p21_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p21_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 23 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '69.9 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Trabalhe com Parceiros</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A empresa criou uma operação dedicada para corporate venture, de acordo com a maturidade corporativa e importância estratégica?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p22_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p22_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p22_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p22_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p22_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p22_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p22_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 24 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '73.2 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Trabalhe com Parceiros</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A empresa sabe onde procurar startups e outros parceiros?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p23_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p23_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p23_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p23_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p23_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p23_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p23_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 25 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '76.5 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Trabalhe com Parceiros</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A empresa consegue dar velocidade para as iniciativas de inovação?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p24_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p24_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p24_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p24_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p24_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p24_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p24_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 26 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '79.9 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Trabalhe com Parceiros</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A empresa utiliza de startups ou parceiros para desenvolver soluções para seus problemas?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p25_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p25_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p25_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p25_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p25_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p25_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p25_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 27 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '83.2 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Cliente no centro</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Os times de projetos utilizam os "trabalhos a serem feitos para o cliente" (Job to be Done) de maneira clara e documentada através de conversas recorrentes e próximas com o cliente?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p26_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p26_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p26_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p26_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p26_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p26_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p26_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 28 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '86.5 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Cliente no centro</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Todas as decisões são tomadas na perspectiva do cliente final?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p27_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p27_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p27_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p27_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p27_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p27_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p27_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 29 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '89.9 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Cliente no centro</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Há um trabalho de Sucesso do Cliente (Customer Sucess) empenhado na melhor experiencia do cliente?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p28_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p28_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p28_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p28_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p28_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p28_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p28_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 30 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '93.2 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Cliente no centro</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">É realizado uma mudança de rota nos serviços baseado nos feedbacks imediatos dos clientes?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p29_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p29_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p29_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p29_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p29_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p29_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p29_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 32 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '96.5 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Cliente no centro</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Quando está desenvolvendo uma nova solução a empresa entrevista seus consumidores para entender suas dores e necessidades?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção que mais se aplica.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p30_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p30_op_1">Totalmente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p30_op_2">Maioritariamente de acordo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p30_op_3">Nem de acordo, nem em desacordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p30_op_5">Pouco de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p30_op_6">Nada de acordo </li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p30_op_4">Não se aplica</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>}
]
/* fim steps */

function Wizard({
  updateFormValues,
  onBackClick,
  logSubmit,
  updateWizardData,
  data,
}) {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const optionClickHandler = (e) => {
      const clickedOption = e.target.closest(".services-select-option li");
      if (clickedOption) {
        const newData = handleOptionClick(e, data)
        updateWizardData(newData);


        console.log("Updated data:", newData);

        const options = document.querySelectorAll(".services-select-option li");
        options.forEach((option) => option.classList.remove("active"));
        clickedOption.classList.add("active");

        
        updateFormValues(newData);
      }
    };

    document.addEventListener("click", optionClickHandler);

    return () => {
      document.removeEventListener("click", optionClickHandler);
    };
  }, [updateFormValues, data]);

  const handleNext = () => {
    const activeOptions = document.querySelectorAll(".services-select-option li.active");
    
    if (activeOptions.length === 0) {
      const options = document.querySelectorAll(".services-select-option li");
      options.forEach((option) => {
        option.style.border = "1px solid red";
      });
      return; // Não vai para o próximo passo
    }
  
    if (activeStep === steps.length - 1) {
        logSubmit(data); // Chama logSubmit quando estiver no último passo
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className="w-full lg:flex-1 2xl:flex 2xl:flex-col 2xl:items-center h-full z-10">
      <ThemeProvider theme={theme}>
        <Stepper activeStep={activeStep}>
          {steps.map((step, index) => (
            <Step key={index}>
              <div
                className={`w-full ${
                  activeStep === index
                    ? "animate__animated animate__fadeInLeft"
                    : ""
                }`}
              >
                {activeStep === index && (
                  <div className="w-full">{step.content}</div>
                )}
              </div>
            </Step>
          ))}
        </Stepper>
      </ThemeProvider>
      <div className="flex sticky bottom-0 z-[100] p-5 md:p-4 space-x-5 bg-white-greyish action-div justify-center w-full sm:hidden">
        <button
          onClick={activeStep === 0 ? onBackClick : handleBack}
          className="bg-black text-white flex p-5 md:p-4 lg:p-4 lg:w-5/12 2xl:w-4/12 w-4/12 2xl:text-lg justify-around items-center text-sm transition ease-in-out hover:text-white hover:scale-105 duration-200 focus:text-white delay-400"
          type="button"
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            style={{ color: "#ffffff" }}
            size="lg"
          />
          Voltar para o inicio
        </button>
        <button
        id="next"
          onClick={handleNext}
          type='button' className="js-btn-next bg-primary text-white w-6/12 lg:w-8/12 2xl:w-4/12 2xl:text-lg p-5 md:p-4 lg:p-4 flex justify-around transition ease-in-out hover:text-white hover:scale-105 duration-200 focus:text-white delay-400"
        >
          {activeStep === steps.length - 1
            ? "Enviar respostas"
            : "Avançar para a próxima etapa"}
          {activeStep !== steps.length - 1 && (
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{ color: "#ffffff" }}
              size="lg"
            />
          )}
        </button>
      </div>
      <div className="flex flex-col justify-center items-center w-full md:hidden lg:hidden xl:hidden 2xl:hidden p-2">
      <button
        id="next"
          onClick={handleNext}
          className="js-btn-next bg-primary text-white w-full p-4 flex justify-around items-center hover:text-white focus:text-white"
          type="button"
        >
          {activeStep === steps.length - 1
            ? "Enviar respostas"
            : "Avançar para a próxima etapa"}

        </button>
        <button
          onClick={activeStep === 0 ? onBackClick : handleBack}
          className="text-black-opac p-5 flex w-full items-center justify-center font-semibold text-center"
          type="button"
        >
          Voltar
        </button>
      </div>
      {/*
        <div>
          {activeStep + 1} de {steps.length}
        </div>
        */}
    </div>
  );
}

export default Wizard;
