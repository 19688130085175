import Feedback from "./feedback";

export const handleOptionClick = (e, data) => {
  const [p, op, value] = e.target.id.split('_');

  // Atualizar estilos
  document.getElementById(`${e.target.id}`).style.borderColor = "#622288";
  const optionIds = [1, 2, 3, 4, 5, 6];
  optionIds.forEach((optionId) => {
    if (e.target.id !== `${p}_op_${optionId}`) {
      document.getElementById(`${p}_op_${optionId}`).style.borderColor = "#ededed";
    }
  });

  let updatedData = [...data]; // Faça uma cópia dos dados existentes

  // Atualizar dados
  let isExists = -1;
  for (let i = 0; i < updatedData.length; i++) {
    if (updatedData[i].hasOwnProperty(p)) {
      isExists = i;
      break;
    }
  }
  if (isExists >= 0) {
    updatedData[isExists] = { [p]: value };
  } else {
    updatedData.push({ [p]: value });
  }

  console.log("dados atualizados:", updatedData);

  // Atualizar classe ativa
  const options = document.querySelectorAll(".services-select-option li");
  options.forEach(option => option.classList.remove("active"));
  e.target.classList.add("active");

  const anyActive = Array.from(options).some(option => option.classList.contains("active"));

  // Desabilitar o botão "next" se nenhum elemento estiver ativo
  const nextButton = document.getElementById("next");
  if (!anyActive) {
    nextButton.disabled = true;
  } else {
    nextButton.disabled = false;
  }
  
  console.log('ativo')
  return updatedData;
};