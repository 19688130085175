import React, {useState, useEffect} from 'react';
import '../index.css'
import '../assets/main'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Wizard from './wizard';

function Form({ showFeedback }) {  
    const [showWiz, setShowWiz] = useState(false);
    const [showForms, setShowForms] = useState(true);
    const [data, setData] = useState([{ product: 'teste' }]);
    const [reEmail, setReEmail] = useState('');
    const [emailMatch, setEmailMatch] = useState(true);
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
      state: '',
      city: '',
      company: '',
      type_company: '',
      occupation: '',
      number_colaborator: '',
      segment: '',

    });

    useEffect(() => {
      verifyValuesAndUpdateData();
    }, [reEmail, formData.email]);

    const verifyValuesAndUpdateData = () => {

      if (formData.email === reEmail) {
        setEmailMatch(true);
      } else {
        setEmailMatch(false);
      }

      const updatedData = [...data];
    
      Object.keys(formData).forEach((input) => {
        let value = formData[input];

        if (typeof value === 'string') {
          value = value.trim(); // Remove leading/trailing whitespace
        } else if (typeof value === 'number') {
          value = value.toString().trim();
        }
    
        if (value !== '') {
          const isExists = updatedData.findIndex((ask) => ask[input]);
    
          if (isExists >= 0) {
            updatedData[isExists][input] = value;
          } else {
            updatedData.push({ [input]: value });
          }
        }
      });
    
      setData(updatedData);
    };
    


    const updateFormData = (newData) => {
      setFormData((prevData) => ({
        ...prevData,
        ...newData,
      }));
    };

    const updateWizardData = (newData) => {
      setData(newData)
      console.log("wizard data em form.js:", newData);
    };    

    const handleShowForm = () => {
      setShowWiz(false);
      setShowForms(true);
    }

    const handleGoBackClick = () => {
      setShowForms(false);
    }

    const handleShowWiz = () => {
      const hasEmptyValues = Object.entries(formData).some(([key, value]) => {
        if (typeof value === 'string') {
          return value.trim() === '';
        }
        if (typeof value === 'number') {
          return value.toString().trim() === '';
        }
        return false; // Se não for uma string ou número, não é um valor nulo
      });
    
      if (hasEmptyValues) {
        alert('Por favor, preencha todos os campos.');
        return; 
      }
    
      // Se não houver nenhum valor nulo, prossegue com a ação
      setShowWiz(true);
      setShowForms(false);
    };

    const handleInputChange = (event) => {
      const { name, value } = event.target;
    
      if (name === 'phone') {
        let formattedValue = value.replace(/\D/g, ''); 
        formattedValue = formattedValue.replace(/^(\d{2})(\d)(\d{4})(\d{4})$/, '($1) $2 $3-$4'); // Formate o telefone
        setFormData((prevData) => ({
          ...prevData,
          [name]: formattedValue,
        }));
      } else {
        if (name === 're_email') {
          setReEmail(value);
        } else {
          /*
          setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
          */
          formData[name] = value;
        }
      }

      verifyValuesAndUpdateData();
    };
    
    const handleSelectChange = (event) => {
      const { name, value } = event.target;
      /*
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      */
      formData[name] = value;
  
      verifyValuesAndUpdateData();
    };

    const logSubmit = (data) => {
      console.log('enviado do form', data);
      
      //const url = 'http://localhost:8082/api';
      //const url = 'https://dm1.acecortex.com.br/api';
      const url = ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'http://localhost:8082/submit' : '/submit');

      const request = new Request(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application.json',
          'Content-Type': 'application/json'
        }
      });
      fetch(request)
      .then(response => response.json())
      .then(function (response) {
        console.log('response', response);
        function insert_comments(comments = [], context = '') {
          comments.map((comment) => {
            if (comment?.length >= 1) {
              const _ul = document.getElementById(`${context}-comments-area`);
              if (_ul) {
                const new_li = document.createElement("li");
                const new_p = document.createElement("p");
                new_p.style.textAlign = 'left';
                const new_p_content = document.createTextNode(comment);
    
                _ul.appendChild(new_li);
                new_p.appendChild(new_p_content);
                new_li.appendChild(new_p);
              }
            }
          });
        }
    
        const design_organizacional = response.design_organizacional.media;
        const design_organizacional_comment = response.design_organizacional.comment;
                    const design_organizacional_comments_answer = response.design_organizacional.comments;
 
  
        const pci = response.pci.media;
        const pci_comment = response.pci.comment;
        const pci_comments_answer = response.pci.comments;

  
       const gestao_agil = response.gestao_agil.media;
                    const gestao_agil_comment = response.gestao_agil.comment;
                    const gestao_agil_comments_answer = response.gestao_agil.comments;
                    const gestao_agil_width = gestao_agil * 200 / 10;
  
                    const msn = response.msn.media;
                    const msn_comment = response.msn.comment;
                    const msn_comments_answer = response.msn.comments;
                    const msn_width = msn * 200 / 10;
  
                    const trabalhe_parceiros = response.trabalhe_parceiros.media;
                    const trabalhe_parceiros_comment = response.trabalhe_parceiros.comment;
                    const trabalhe_parceiros_comments_answer = response.trabalhe_parceiros.comments;
                    const trabalhe_parceiros_width = trabalhe_parceiros * 200 / 10;
  
                    const cliente_centro = response.cliente_centro.media;
                    const cliente_centro_comment = response.cliente_centro.comment;
                    const cliente_centro_comments_answer = response.cliente_centro.comments;
                    const cliente_centro_width = cliente_centro * 200 / 10;
        
  
          // randerização
          showFeedback();

          function calcularCor(valor) {
            if (valor < 5) {
              return '#DD4563';
            } else if (valor >= 5 && valor < 8) {
              return '#FF9433';
            } else if (valor >= 8 && valor <= 10) {
              return '#19B419';
            }
          }

          function atualizarElemento(id, valor, comentario, comentariosResposta, largura) {
            const elementoNota = document.getElementById(id);
            const elementoComentario = document.getElementById(`${id}-comment`);
          
            elementoComentario.innerHTML = comentario;
            elementoNota.innerHTML = valor ? `${Number(valor).toFixed(1)}` : id.replace(/_/g, ' ').toUpperCase();
          
            const cor = calcularCor(valor);
            elementoNota.style.color = cor;
          
            insert_comments(comentariosResposta, id);
          }
          

      waitForFeedbackRender()
     .then(() => {
          
      atualizarElemento('design_organizacional', design_organizacional, design_organizacional_comment, design_organizacional_comments_answer);
      atualizarElemento('pci', pci, pci_comment, pci_comments_answer, 'PENSE COMO INVESTIDOR');
      atualizarElemento('msn', msn, msn_comment, msn_comments_answer, 'MATE SEU NEGÓCIO');
      atualizarElemento('trabalhe_parceiros', trabalhe_parceiros, trabalhe_parceiros_comment, trabalhe_parceiros_comments_answer);
      atualizarElemento('gestao_agil', gestao_agil, gestao_agil_comment, gestao_agil_comments_answer);
      atualizarElemento('cliente_centro', cliente_centro, cliente_centro_comment, cliente_centro_comments_answer);
          
      })
       // Handle response we get from the API
      }).catch((e) => console.log(e))
      // console.log.textContent = `Formulário Submetido! Time stamp: ${event.timeStamp}`;
      console.log(event);
  }
  

  const waitForFeedbackRender = () => {
    return new Promise((resolve, reject) => {
      const checkRenderInterval = setInterval(() => {
        const feedbackElement = document.getElementById('feedback');
        if (feedbackElement) {
          clearInterval(checkRenderInterval); 
          resolve();
        }
      }, 100); // Verifique a cada 100ms

      setTimeout(() => {
        clearInterval(checkRenderInterval);
        reject(new Error('Timeout waiting for feedback render'));
      }, 80000); // 10 segundos
    });
  }


    return (
        /* inicio */
        <div className="overflow-auto w-7/12 lg:flex-1 2xl:w-8/12 space-y-6 overflow-x-hidden 2xl:justify-center 2xl:align-middle 2xl:flex sm:overflow-visible md:overflow-visible sm:w-full md:w-full" >
          <form className="multisteps-form__form w-full lg:flex-1" noValidate> 
            <div className="form-area w-full h-full lg:flex-1"> 
            {/* div form */} 
            {showForms && (
            <div className="multisteps-form__panel js-active w-full animate__animated animate__fadeInLeft lg:flex-1" >
                <div className="wizard-forms flex flex-col 2xl:items-center">
                  <div className="inner clearfix ">
                    <div className="form-content pera-content p-10 z-10 sticky sm:p-3 ">
                      <div className="step-inner-content  2xl:justify-center 2xl:flex 2xl:flex-col"> 
                      {/* <span className="step-no bottom-line">Step 1</span>
                        <div className="step-progress float-right w-3/12 absolute">
                          <span>1 de 5 completo</span>
                          <div className="step-progress-bar">
                            <div className="progress p-1">
                              <div className="progress-bar"></div>
                            </div>
                          </div>
                        </div> */} 
                        <h2 className="pb-4 text-5xl sm:text-4xl sm:mt-5 sm:font-semibold 2xl:text-6xl">Vamos começar?</h2>
                        <div className="mt-4">
                          <span className="text-lg text-black-opac font-semibold sm:font-normal sm:text-sm"> Para começarmos, precisamos que você preencha algumas informações em relação a você e a sua empresa. </span>
                        </div>
                        <br />
                        <div className="form-inner-area  flex flex-col justify-center space-y-5">
                          <div className="space-y-3 mt-4">
                            <p className="font-semibold sm:text-sm 2xl:text-lg">Nome</p>
                            <input minLength='2' type="text" name="name" className="form-control p-3.5 sm:p-3 2xl:p-5" required placeholder="Digite seu nome "  
                            value={formData.name}
                            onChange={handleInputChange}
                            />
                          </div>
                          <div className="space-y-3">
                            <p className="font-semibold sm:text-sm 2xl:text-lg">E-mail</p>
                            <input minLength='2' type="email" name="email" className="form-control p-3.5 sm:p-3 2xl:p-5" required                         
                            value={formData.email}
                            onChange={handleInputChange} 
                            placeholder="E-mail " 
                            />
                          </div>
                          <div className="space-y-3">
                            <p className="font-semibold sm:text-sm 2xl:text-lg">Confirme seu e-mail</p>
                            <input minLength='2' type="email" name="re_email" className="form-control p-3.5 sm:p-3 2xl:p-5" required                         
                            placeholder="Informe novamente seu e-mail " 
                            value={reEmail}
                            onChange={handleInputChange}
                            />
                            {!emailMatch  && <span className=" text-red-600 sm:text-xs md:text-sm lg:text-sm">Os emails não correspondem</span>}

                          </div>
                          <div className="space-y-3">
                            <p className="font-semibold sm:text-sm 2xl:text-lg">Celular</p>
                            <input minLength='2' type="text" name="phone" placeholder="(...)" className="form-control p-3.5 sm:p-3 2xl:p-5" required                          
                            value={formData.phone}
                            onChange={handleInputChange} 
                            />
                          </div>
                          <div className="space-y-3">
                            <p className="font-semibold sm:text-sm 2xl:text-lg">Estado</p>
                            <div className="budget-area">
                              <select className="p-3.5 sm:p-3 2xl:p-5 text-opaque" required name="state"
                                value={formData.state}
                                onChange={handleSelectChange}>
                                <option value="" disabled defaultValue>Exemplo: SP</option>
                                <option>Acre</option>
                                <option>Alagoas</option>
                                <option>Amapá</option>
                                <option>Amazonas</option>
                                <option>Bahia</option>
                                <option>Ceará</option>
                                <option>Distrito Federal</option>
                                <option>Espírito Santo</option>
                                <option>Goiás</option>
                                <option>Maranhão</option>
                                <option>Mato Grosso</option>
                                <option>Mato Grosso do Sul</option>
                                <option>Minas Gerais</option>
                                <option>Pará</option>
                                <option>Paraíba</option>
                                <option>Paraná</option>
                                <option>Pernambuco</option>
                                <option>Piauí</option>
                                <option>Rio de Janeiro</option>
                                <option>Rio Grande do Norte</option>
                                <option>Rio Grande do Sul</option>
                                <option>Rondônia</option>
                                <option>Roraima</option>
                                <option>Santa Catarina</option>
                                <option>São Paulo</option>
                                <option>Sergipe</option>
                                <option>Tocantins</option>
                              </select>
                            </div>
                          </div>
                          <div className="form-inner-area space-y-3">
                            <p className="font-semibold sm:text-sm 2xl:text-lg">Cidade</p>
                            <input minLength='2' type="text" name="city" placeholder="Digite o nome da cidade em que você reside" className="form-control p-3.5 sm:p-3 2xl:p-5" required                          
                            value={formData.city}
                            onChange={handleInputChange}
                            />
                          </div>
                          <div className="space-y-3">
                            <p className="font-semibold sm:text-sm 2xl:text-lg">Empresa</p>
                            <input minLength='2' type="text" name="company" placeholder="Digite o nome da sua empresa" className="form-control p-3.5 sm:p-3 2xl:p-5" required                          
                            value={formData.company}
                            onChange={handleInputChange} 
                            />
                          </div> 
                          {/* <p>Nome do produto, serviço ou ideia</p> */} 
                          <input minLength='2' type="hidden" name="product" value="teste" placeholder="Nome do produto, serviço ou ideia" />
                          <div className="space-y-3">
                            <p className="font-semibold sm:text-sm 2xl:text-lg">Tipo de Empresa</p>
                            <div className="budget-area">
                              <select className="p-3.5 sm:p-3 2xl:p-5 text-opaque" required name="type_company"
                                value={formData.type_company}
                                onChange={handleSelectChange}>
                                <option value="" disabled selected>Selecione o tipo que mais se aplica</option>
                                <option value="Startup">Startup</option>
                                <option value="Corporação">Corporação</option>
                              </select>
                            </div>
                          </div>
                          <div className="space-y-3">
                            <p className="font-semibold sm:text-sm 2xl:text-lg">Cargo</p>
                            <div className="budget-area">
                              <select className="p-3.5 sm:p-3 2xl:p-5 text-opaque" required name="occupation"
                                value={formData.occupation}
                                onChange={handleSelectChange}>
                                <option value="" disabled selected>Selecione o cargo que mais se aplica</option>
                                <option value="Conselheiro">Conselheiro</option>                                
                                <option value="C-level">C-level</option>                                
                                <option value="Diretor">Diretor</option>
                                <option value="Gerente">Gerente</option>
                                <option value="Coordenador">Coordenador</option>
                                <option value="Supervisor">Supervisor</option>                                
                                <option value="Analista">Analista</option>
                                <option value="Assistente">Assistente</option>                                
                                <option value="Estagiário">Estagiário</option>
                              </select>
                            </div>
                          </div>
                          <div className="space-y-3">
                            <p className="font-semibold sm:text-sm 2xl:text-lg">Quantos funcionários a sua empresa possui?</p>
                            <div className="budget-area">
                              <select className="p-3.5 sm:p-3 2xl:p-5 text-opaque" required name="number_colaborator" 
                                value={formData.number_colaborator}
                                onChange={handleSelectChange}>
                                <option value="" disabled selected>Selecione o que mais se aplica</option>
                                <option value="0-100">De 1 até 100 colaboradores</option>
                                <option value="101-400">De 101 até 400 colaboradores</option>
                                <option value="401-900">De 401 até 900 colaboradores</option>                                
                                <option value="+1.000">Acima de 1000 colaboradores</option>
                              </select>
                            </div>
                          </div>
                          <div className="space-y-3">
                            <p className="font-semibold sm:text-sm 2xl:text-lg">Segmento da empresa</p>
                            <div className="budget-area">
                              <select className="p-3.5 sm:p-3 2xl:p-5 text-opaque" required name="segment"
                                value={formData.segment}
                                onChange={handleSelectChange}>
                                <option className="text-opaque" value="" disabled selected>Selecione o que mais se aplica</option>
                                <option value="Inovação">Inovação</option>
                                <option value="Negócios">Negócios</option>
                                <option value="TI">TI</option>
                                <option value="RH">RH</option>
                                <option value="Financeiro">Financeiro</option>
                                <option value="Operações">Operações</option>
                                <option value="Marketing">Marketing</option>
                                <option value="Outra">Outra</option>
                                <option value="Edtech">Edtech</option>
                                <option value="E-Commerce">E-Commerce</option>
                                <option value="Agrotech">Agrotech</option>
                                <option value="MarTech">MarTech</option>
                                <option value="Fintech">Fintech</option>
                                <option value="Energia">Energia</option>
                                <option value="Outro">Outro</option>
                                <option value="Gestão">Gestão</option>
                                <option value="Big Data">Big Data</option>
                                <option value="CyberSecurity">CyberSecurity</option>
                                <option value="LawTech">LawTech</option>
                                <option value="InsurTech">InsurTech</option>
                                <option value="Varejo">Varejo</option>
                                <option value="Machine Learning">Machine Learning</option>
                                <option value="RiskTech">RiskTech</option>
                                <option value="Beneficios">Beneficios</option>
                                <option value="HealthTech">HealthTech</option>
                                <option value="RHTech">RHTech</option>
                                <option value="AI-FinTech">AI-FinTech</option>
                                <option value="Construtech">Construtech</option>
                                <option value="Industrial">Industrial</option>
                                <option value="Logística">Logística</option>
                                <option value="IoT">IoT</option>
                                <option value="SMB">SMB</option>
                                <option value="Serviço">Serviço</option>
                                <option value="Real Estate">Real Estate</option>
                                <option value="Telecom">Telecom</option>
                                <option value="Autotech">Autotech</option>
                                <option value="Cybersecurity">Cybersecurity</option>
                                <option value="Bots">Bots</option>
                                <option value="Blockchain">Blockchain</option>
                                <option value="Analytics">Analytics</option>
                                <option value="Real State">Real State</option>
                                <option value="Marketplace">Marketplace</option>
                                <option value="Inteligencia Artificial">Inteligencia Artificial</option>
                                <option value="P2P">P2P</option>
                                <option value="LegalTechs ou Direito">LegalTechs ou Direito</option>
                                <option value="RetailTechs ou Varejo">RetailTechs ou Varejo</option>
                                <option value="LogTechs ou Logística">LogTechs ou Logística</option>
                                <option value="EnergyTechs ou Energia">EnergyTechs ou Energia</option>
                                <option value="HRTechs ou Recursos Humanos">HRTechs ou Recursos Humanos</option>
                                <option value="FinTechs ou Financeiro">FinTechs ou Financeiro</option>
                                <option value="HealthTechs ou Saúde">HealthTechs ou Saúde</option>
                                <option value="Internet of Things ou Internet das Coisas">Internet of Things ou Internet das Coisas</option>
                                <option value="MarTechs ou Marketing">MarTechs ou Marketing</option>
                                <option value="AgriTechs ou Agricultura">AgriTechs ou Agricultura</option>
                                <option value="Productivity ou Produtividade">Productivity ou Produtividade</option>
                                <option value="Traveltech">Traveltech</option>
                                <option value="CleanTechs ou Sustentabilidade">CleanTechs ou Sustentabilidade</option>
                                <option value="Mercado audiovisual">Mercado audiovisual</option>
                                <option value="Backend Nocode">Backend Nocode</option>
                                <option value="Gestão de terceiros (indústrias, shoppings, educação, entre outros segmentos)">Gestão de terceiros (indústrias, shoppings, educação, entre outros segmentos)</option>
                                <option value="Fashiontech">Fashiontech</option>
                                <option value="Plataforma de Informação sobre Mercado Financeiro">Plataforma de Informação sobre Mercado Financeiro</option>
                                <option value="Soluções de Outsourcing">Soluções de Outsourcing</option>
                                <option value="Proteção de dados">Proteção de dados</option>
                                <option value="Customer Service ou Atendimento ao Cliente">Customer Service ou Atendimento ao Cliente</option>
                                <option value="Minetech">Minetech</option>
                                <option value="EdTechs ou Educação">EdTechs ou Educação</option>
                                <option value="Turistech ou Turismo Educacional">Turistech ou Turismo Educacional</option>
                                <option value="ESG">ESG</option>
                                <option value="AdTechs ou Publicidade">AdTechs ou Publicidade</option>
                                <option value="RegTechs ou Regulatório">RegTechs ou Regulatório</option>
                                <option value="Participações e serviços">Participações e serviços</option>
                                <option value="Gaming & Computacao em Nuvem">Gaming & Computacao em Nuvem</option>
                                <option value="ConstruTechs ou Construção">ConstruTechs ou Construção</option>
                                <option value="FoodTechs ou Comida">FoodTechs ou Comida</option>
                                <option value="Insurtech">Insurtech</option>
                                <option value="SportTech">SportTech</option>
                                <option value="Proptech">Proptech</option>
                                <option value="Sistemas/Tecnologia/Facilities">Sistemas/Tecnologia/Facilities</option>
                                <option value="GovTechs ou Governo">GovTechs ou Governo</option>
                                <option value="BeuatyTech">BeuatyTech</option>
                                <option value="Rede Social">Rede Social</option>
                                <option value="AutoTechs ou Automotivo">AutoTechs ou Automotivo</option>
                                <option value="Serviços">Serviços</option>
                                <option value="Mobility ou Mobilidade">Mobility ou Mobilidade</option>
                                <option value="cosmeticos">cosmeticos</option>
                                <option value="Loja">Loja</option>
                                <option value="TravelTechs">TravelTechs</option>
                                <option value="Greentech">Greentech</option>
                                <option value="Cybertech">Cybertech</option>
                                <option value="IndTechs ou Indústria">IndTechs ou Indústria</option>
                                <option value="vc">vc</option>
                                <option value="Segurança Cibernética">Segurança Cibernética</option>
                                <option value="RPA ou Automação de Processos Robóticos">RPA ou Automação de Processos Robóticos</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> 
                  {/* /.inner */}        
                  <div className="flex sticky bottom-0 z-[100] p-5 lg:p-4 md:p-4 space-x-5 bg-white-greyish action-div justify-center w-full sm:hidden">
                    <button className="bg-black text-white flex p-5 md:p-4 lg:p-4 lg:w-5/12 2xl:w-4/12 w-4/12 2xl:text-lg justify-around items-center text-sm transition ease-in-out hover:text-white hover:scale-105 duration-200 focus:text-white delay-400">
                      <FontAwesomeIcon icon={faArrowLeft} style={{ color: "#ffffff" }} size='lg' />
                      Voltar para o início 
                    </button>
                    <button title="NEXT" type='button' className="js-btn-next bg-primary text-white w-6/12 lg:w-8/12 2xl:w-4/12 2xl:text-lg p-5 md:p-4 lg:p-4 flex justify-around transition ease-in-out hover:text-white hover:scale-105 duration-200 focus:text-white delay-400" onClick={handleShowWiz}>
                      Avançar para a próxima etapa 
                      <FontAwesomeIcon icon={faArrowRight} style={{ color: "#ffffff" }} size='lg' />
                    </button>
                    
                  </div>
                  <div className="flex flex-col justify-center items-center w-full md:hidden lg:hidden xl:hidden 2xl:hidden">
                    <button title="NEXT" type='button' className="js-btn-next bg-primary text-white w-11/12 p-4 flex justify-around" onClick={handleShowWiz}>
                      Avançar para a próxima etapa 
                    </button>
                    <button className="text-black-opac font-semibold sm:text-sm p-5 flex w-11/12 justify-around items-center text-sm"  type="button" onClick={handleGoBackClick}>
                      Voltar para o início 
                    </button>

                    
                  </div>
                </div>
                </div>
                /* Fim form area */
                )}
                {showWiz ? (
                    <Wizard updateFormValues={updateFormData} updateWizardData={updateWizardData} onBackClick={handleShowForm} logSubmit={() => logSubmit(data)} data={data}/>
                ) : null } 
                </div>
          </form>

    </div> 
    )
}

export default Form;