import React, { useState,useEffect } from 'react';
import "../index.css";
import "../assets/main";
import 'tailwindcss/tailwind.css';
import bgFeedBack from "../images/imgfeedback.png";
import bgMobileFeedback from "../images/headerMobile.svg"
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

 
const theme = createTheme({
    components: {
      MuiStepper: {
        styleOverrides: {
          root: {
            display: 'block', 
            WebkitBox: 'initial', 
            WebkitFlex: 'initial',
          },
        },
      },
    },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
    },
});

function Feedback() {
  
	useEffect(() => {
		const rootDiv = document.getElementById('root');
		const feedbackDiv = document.getElementById('feedback');
	
		const observer = new IntersectionObserver(entries => {
		  entries.forEach(entry => {
			if (entry.isIntersecting) {
			  rootDiv.classList.add('mb-body');
			} else {
			  rootDiv.classList.remove('mb-body');
			}
		  });
		});
	
		observer.observe(feedbackDiv);
	
		return () => {
		  observer.disconnect();
		};
	  }, [])

	  const scrollToSection = () => {
		const element = document.getElementById("target");
		if (element) {
		  element.scrollIntoView({ behavior: "smooth" });
		}
	  }
	  
  return (
    <div className="w-full 2xl:w-10/12 flex flex-col items-center lg:space-y-8 lg:pt-0 xl:space-y-8 xl:pt-0 2xl:space-y-8 2xl:pt-0 md:space-y-8 md:pt-0  bg-white-greyish h-screen sm:overflow-x-hidden" id="feedback">
      <div className="w-full 2xl:w-10/12 xl:w-10/12 flex sm:hidden relative">
      	<div className="overflow-hidden flex 2xl:mt-10 items-start w-full">
        	<img src={bgFeedBack} className=" w-full align-top"/>
		</div>
      </div>

	  	<div className=" w-full flex items-start md:hidden lg:hidden xl:hidden 2xl:hidden relative ">
        	<img src={bgMobileFeedback} className=" w-full align-top"/>
		</div>

		{/* Elemento div para tablets e telas de desktops */}
      <div id="target" className="w-11/12 2xl:w-10/12 xl:w-10/12 p-10 max-h-80 sm:flex sm:flex-col sm:items-center sm:mt-7 space-y-4 bg-white-greyish mb-30">
		<ThemeProvider theme={theme}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
				<div className="w-full p-2 sm:p-1 flex flex-row justify-between items-center">
					<span className="text-2xl sm:text-xl 2xl:text-3xl xl:text-3xl font-semibold text-white cormorant-font">Design Organizacional</span>
					<div className="flex flex-row w-auto p-2 rounded-[63px] bg-white-greyish items-center">
						<p className=" sm:text-lg md:text-lg 2xl:text-2xl xl:text-2xl text-xl font-semibold" id="design_organizacional"></p>
						<p className="sm:text-lg md:text-lg 2xl:text-2xl xl:text-2xl text-xl text-black-opac"> /10</p>
					</div> 
				</div>
            </AccordionSummary>
            <AccordionDetails>
			<div className="text-black-opac mt-4">
				<p className="bg-[#D9D9D9] p-4 sm:p-2 sm:text-xs rounded-lg hidden" id="design_organizacional-comment">Mapeamento de Design Organizacional com indícios de necessidade de atenção</p>
					<div id="design_organizacional-progress-area"  >
						<div id="design_organizacional_toggle">
							<div className="p-8 sm:p-2">
								<ul id="design_organizacional-comments-area" className="space-y-4 sm:space-y-3  list-none sm:text-xs sm:p-4"></ul>
							</div>
						</div>
					</div>
			</div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
				<div className="w-full p-2 sm:p-1 flex flex-row justify-between items-center">
					<span className="text-2xl sm:text-xl 2xl:text-3xl xl:text-3xl font-semibold text-white cormorant-font">Pense como Investidor</span>
					<div className="flex flex-row w-auto bg-white-greyish rounded-[63px] p-2 items-center">
						<p className=" sm:text-lg md:text-lg 2xl:text-2xl xl:text-2xl text-xl font-semibold" id="pci"></p>
						<p className="sm:text-lg md:text-lg 2xl:text-2xl xl:text-2xl text-xl text-black-opac"> /10</p>
					</div> 
				</div>
            </AccordionSummary>
            <AccordionDetails>
			<div className="text-black-opac mt-4">
				<p className="bg-[#D9D9D9] p-4 sm:p-2 sm:text-xs rounded-lg hidden"  id="pci-comment"></p>
					<div id="pci-progress-area"  >
						<div id="pci_toggle">
							<div className="p-8 sm:p-2">
								<ul id="pci-comments-area" className="space-y-4 sm:space-y-3  list-none sm:text-xs sm:p-4"></ul>
							</div>
						</div>
					</div>
			</div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
				<div className="w-full p-2 sm:p-1 flex flex-row justify-between items-center">
					<span className="text-2xl sm:text-xl 2xl:text-3xl xl:text-3xl font-semibold text-white cormorant-font">Gestão Ágil</span>
					<div className="flex flex-row w-auto bg-white-greyish rounded-[63px] p-2 items-center">
					<p className=" sm:text-lg md:text-lg 2xl:text-2xl xl:text-2xl text-xl font-semibold" id="gestao_agil"></p>
					<p className="sm:text-lg md:text-lg 2xl:text-2xl xl:text-2xl text-xl text-black-opac"> /10</p>
					</div> 
				</div>
            </AccordionSummary>
			<AccordionDetails>
			<div className="text-black-opac mt-4">
			<p className="bg-[#D9D9D9] p-4 sm:p-2 sm:text-xs rounded-lg hidden"  id="gestao_agil-comment"></p>
					<div id="gestao_agil-progress-area">
						<div id="gestao_agil_toggle">
							<div className="p-8 sm:p-2">
								<ul id="gestao_agil-comments-area" className="space-y-4 sm:space-y-3  list-none sm:text-xs sm:p-4"></ul>
							</div>
						</div>
					</div>
			</div>
			</AccordionDetails>
          </Accordion>
		  <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
				<div className="w-full p-2 sm:p-1 flex flex-row justify-between items-center">
					<span className="text-2xl sm:text-xl 2xl:text-3xl xl:text-3xl font-semibold text-white cormorant-font">Mate seu negócio</span>
					<div className="flex flex-row w-auto bg-white-greyish rounded-[63px] p-2 items-center">
					<p className=" sm:text-lg md:text-lg 2xl:text-2xl xl:text-2xl text-xl font-semibold" id="msn"></p>
					<p className="sm:text-lg md:text-lg 2xl:text-2xl xl:text-2xl text-xl text-black-opac"> /10</p>
					</div> 
				</div>
            </AccordionSummary>
			<AccordionDetails>
			<div className="text-black-opac mt-4">
			<p className="bg-[#D9D9D9] p-4 sm:p-2 sm:text-xs rounded-lg hidden"  id="msn-comment"></p>
					<div id="msn-progress-area"  >
						<div id="msn_toggle">
							<div className="p-8 sm:p-2">
								<ul id="msn-comments-area" className="space-y-4 sm:space-y-3  list-none sm:text-xs sm:p-4"></ul>
							</div>
						</div>
					</div>
			</div>
			</AccordionDetails>
          </Accordion>
		  <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
				<div className="w-full p-2 sm:p-1 flex flex-row justify-between items-center">
					<span className="text-2xl sm:text-xl 2xl:text-3xl xl:text-3xl font-semibold text-white cormorant-font">Trabalhe com parceiros</span>
					<div className="flex flex-row w-auto bg-white-greyish rounded-[63px] p-2 items-center">
					<p className=" sm:text-lg md:text-lg 2xl:text-2xl xl:text-2xl text-xl font-semibold" id="trabalhe_parceiros"></p>
					<p className="sm:text-lg md:text-lg 2xl:text-2xl xl:text-2xl text-xl text-black-opac"> /10</p>
					</div> 
				</div>
            </AccordionSummary>
			<AccordionDetails>
			<div className="text-black-opac mt-4">
			<p className="bg-[#D9D9D9] p-4 sm:p-2 sm:text-xs rounded-lg hidden"  id="trabalhe_parceiros-comment"></p>
					<div id="trabalhe_parceiros-progress-area"  >
						<div id="trabalhe_parceiros_toggle">
							<div className="p-8 sm:p-2">
								<ul id="trabalhe_parceiros-comments-area" className="space-y-4 sm:space-y-3  list-none sm:text-xs sm:p-4"></ul>
							</div>
						</div>
					</div>
			</div>
			</AccordionDetails>
          </Accordion>
		  <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
				<div className="w-full p-2 sm:p-1 flex flex-row justify-between items-center">
					<span className="text-2xl sm:text-xl 2xl:text-3xl xl:text-3xl font-semibold text-white cormorant-font">Cliente no centro</span>
					<div className="flex flex-row w-auto bg-white-greyish rounded-[63px] p-2 items-center">
					<p className=" sm:text-lg md:text-lg 2xl:text-2xl xl:text-2xl text-xl font-semibold" id="cliente_centro"></p>
					<p className="sm:text-lg md:text-lg 2xl:text-2xl xl:text-2xl text-xl text-black-opac"> /10</p>
					</div> 
				</div>
            </AccordionSummary>
			<AccordionDetails>
			<div className="text-black-opac mt-4">
			<p className="bg-[#D9D9D9] p-4 sm:p-2 sm:text-xs rounded-lg hidden"  id="cliente_centro-comment"></p>
					<div id="cliente_centro-progress-area"  >
						{/* <div class="progress-bar-thankyou">
							<div id="trabalhe_parceiros-progress"></div>
  						</div> */}
						<div id="cliente_centro_toggle" >
							<div className="p-8 sm:p-2">
								<ul id="cliente_centro-comments-area" className="space-y-4 sm:space-y-3  list-none sm:text-xs sm:p-4"></ul>
							</div>
						</div>
					</div>
			</div>
			</AccordionDetails>
          </Accordion>
		  </ThemeProvider>
      	</div>
</div>
  );
}

export default Feedback;

{/*          <div id="thank-you" class="thank-you-div" style={{display: "none"}}>
		    <div class="container">
			<div class="thank-you-wrapper position-relative thank-wrapper-style-one">
				{/* <div class="thank-you-close text-center">x</div> 
				<div class="thank-txt text-center">
					{/* <div class="thank-icon">
						<img src="assets/img/tht1.png" alt="">
					</div> 
					<h1 style={{color:"black"}}>O Diagnóstico de Maturidade de inovação já está disponível em seu e-mail.</h1>
					<p style={{ color: 'black', marginBottom: '10px' }}>Acesse agora para ter insights valiosos sobre como evoluir a estratégia do seu negócio.</p>
					{/* <p style={{textAlign: 'justify', 'marginBottom: 10px'}}>Caro usuário, obrigado por ter preenchido o Diagnóstico de Inovação da sua empresa. 
						Sabemos que o seu tempo é valioso e agradecemos por ter se dedicado a esse questionário.
					</p>
					<p style={{textAlign: 'justify', 'marginBottom: 10px'}}>O Diagnóstico de Inovação Empresarial é uma ferramenta analítica que oferece insights valiosos sobre o modelo de negócio, 
						a equipe envolvida, a tração, a solução proposta, o estágio de desenvolvimento da solução e a jornada do cliente.
					</p>
					<p style={{textAlign: 'justify', marginBottom: '20px'}}>Com base em suas respostas, podemos analisar diversos aspectos do projeto e do mercado, identificando possíveis melhorias 
						e mudanças de processos para gerar mais valor. Agradecemos novamente pela sua colaboração e esperamos que a solução de Diagnóstico de Inovação Empresarial possa servir como uma referência 
						valiosa para aprimorar seus processos de gestão de inovação.
					</p> */}
					{/* <div id="design_organizacional_title" onclick="collapsed('design_organizacional_title')" data-toggle="collapse" data-target="#design_organizacional_toggle" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
						<h2 id="design_organizacional">DESIGN ORGANIZACIONAL:</h2> 
						<span style={{marginLeft: '17px', paddingTop: '2px', color: '#5756a2'}}><i class="fa fa-chevron-right fa-lg"></i></span>
					</div>
					
					<p className="bg-[#D9D9D9] p-4 sm:p-2 sm:text-xs rounded-lg"  id="design_organizacional-comment">Mapeamento de Design Organizacional com indícios de necessidade de atenção</p>
					<div id="design_organizacional-progress-area"  >
						{/* <div class="progress-bar-thankyou">
							<div id="design_organizacional-progress"></div>
										</div> 
						<div id="design_organizacional_toggle">
							<div className="p-8 sm:p-2">
								<ul id="design_organizacional-comments-area"className="bg-[#D9D9D9] p-4 sm:p-2 sm:text-xs rounded-lg" ></ul>
							</div>
						</div>
					</div>

					<div id="pci_title" onclick="collapsed('pci_title')" data-toggle="collapse" data-target="#pci_toggle" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
						<h2 id="pci">PENSE COMO INVESTIDOR</h2>
						<span style={{marginLeft: '17px', paddingTop: '2px', color: '#5756a2'}}><i class="fa fa-chevron-right fa-lg"></i></span>
					</div>
					<p className="bg-[#D9D9D9] p-4 sm:p-2 sm:text-xs rounded-lg"  id="pci-comment"></p>
					<div id="pci-progress-area"  >

						 <div class="progress-bar-thankyou">
							<div id="pci-progress"></div>
										</div> 
						<div id="pci_toggle">
							<div className="p-8 sm:p-2">
								<ul id="pci-comments-area"className="bg-[#D9D9D9] p-4 sm:p-2 sm:text-xs rounded-lg" ></ul>
							</div>
						</div>
					</div>
	
					<div id="gestao_agil_title" onclick="collapsed('gestao_agil_title')" data-toggle="collapse" data-target="#gestao_agil_toggle" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
						<h2 id="gestao_agil">GESTÃO ÁGIL</h2>
						<span style={{marginLeft: '17px', paddingTop: '2px', color: '#5756a2'}}><i class="fa fa-chevron-right fa-lg"></i></span>
					</div>
					<p className="bg-[#D9D9D9] p-4 sm:p-2 sm:text-xs rounded-lg"  id="gestao_agil-comment"></p>
					<div id="gestao_agil-progress-area"  >
						 <div class="progress-bar-thankyou">
							<div id="gestao_agil-progress"></div>
										</div> 
						<div id="gestao_agil_toggle">
							<div className="p-8 sm:p-2">
								<ul id="gestao_agil-comments-area"className="bg-[#D9D9D9] p-4 sm:p-2 sm:text-xs rounded-lg" ></ul>
							</div>
						</div>
					</div>

					<div id="msn_title" onclick="collapsed('msn_title')" data-toggle="collapse" data-target="#msn_toggle" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
						<h2 id="msn">Mate seu negócio</h2>
						<span style={{marginLeft: '17px', paddingTop: '2px', color: '#5756a2'}}><i class="fa fa-chevron-right fa-lg"></i></span>
					</div>
					<p className="bg-[#D9D9D9] p-4 sm:p-2 sm:text-xs rounded-lg"  id="msn-comment"></p>
					<div id="msn-progress-area"  >
						 <div class="progress-bar-thankyou">
							<div id="msn-progress"></div>
										</div> 
						<div id="msn_toggle">
							<div className="p-8 sm:p-2">
								<ul id="msn-comments-area"className="bg-[#D9D9D9] p-4 sm:p-2 sm:text-xs rounded-lg" ></ul>
							</div>
						</div>
					</div>

					<div id="trabalhe_parceiros_title" onclick="collapsed('trabalhe_parceiros_title')" data-toggle="collapse" data-target="#trabalhe_parceiros_toggle" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
						<h2 id="trabalhe_parceiros">Trabalhe com Parceiros</h2>
						<span style={{marginLeft: '17px', paddingTop: '2px', color: '#5756a2'}}><i class="fa fa-chevron-right fa-lg"></i></span>
					</div>
					<p className="bg-[#D9D9D9] p-4 sm:p-2 sm:text-xs rounded-lg"  id="trabalhe_parceiros-comment"></p>
					<div id="trabalhe_parceiros-progress-area"  >
						 <div class="progress-bar-thankyou">
							<div id="trabalhe_parceiros-progress"></div>
										</div> 
						<div id="trabalhe_parceiros_toggle">
							<div className="p-8 sm:p-2">
								<ul id="trabalhe_parceiros-comments-area"className="bg-[#D9D9D9] p-4 sm:p-2 sm:text-xs rounded-lg" ></ul>
							</div>
						</div>
					</div>

					<div id="cliente_centro_title" onclick="collapsed('cliente_centro_title')" data-toggle="collapse" data-target="#cliente_centro_toggle" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
						<h2 id="cliente_centro">Cliente no Centro</h2>
						<span style={{marginLeft: '17px', paddingTop: '2px', color: '#5756a2'}}><i class="fa fa-chevron-right fa-lg"></i></span>
					</div>
					<p className="bg-[#D9D9D9] p-4 sm:p-2 sm:text-xs rounded-lg"  id="cliente_centro-comment"></p>
					<div id="cliente_centro-progress-area"  >
						<div class="progress-bar-thankyou">
							<div id="cliente_centro-progress"></div>
										</div> 
						<div id="cliente_centro_toggle">
							<div className="p-8 sm:p-2">
								<ul id="cliente_centro-comments-area"className="bg-[#D9D9D9] p-4 sm:p-2 sm:text-xs rounded-lg" ></ul>
							</div>
						</div> 
					</div>
                </div>
            </div>
        </div>
		</div> */}
